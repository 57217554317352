import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LegalCaseState } from '@law-connect/types';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { actions } from '../redux/slices';
import selectors from '../redux/selectors';
import { ScrollToTop } from '../components/scroll-to-top';
import {
  CookieConsent,
  CookieConsentResult,
} from '../components/cookie-consent';
import { security } from '../redux/api/security';
import { NotFoundComponent } from './not-found';
import { MaintenanceComponent } from './maintenance';
import ReactGA from 'react-ga4';
import env from '../constants/env';
import { clarity } from 'react-microsoft-clarity';
import ReactPixel from 'react-facebook-pixel';
import { ToastWrapper } from '../components/toast';
import { Collector } from '../utils/collector';
import {
  AUTH_MODAL_PORTAL_ID,
  FALLBACK_LANGUAGE,
  UNGATED_PREMATTER_COOKIE,
} from '../constants';
import bodyStyles from '../styles/theme.module.less';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../i18n';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';

// Start seeing data on the Clarity dashboard with your id
clarity.init(env.CLARITY_PROJECT_ID);
ReactPixel.init(env.META_PIXEL_ID);
ReactPixel.revokeConsent();

// Lazy Load the components
const LazyHome = React.lazy(() =>
  import('./home').then((module) => ({ default: module.Home }))
);
const LazySailingPage = React.lazy(() =>
  import('./sailing').then((module) => ({ default: module.SailingPage }))
);
const LazyAbout = React.lazy(() =>
  import('./about').then((module) => ({ default: module.About }))
);
const LazyPrivacy = React.lazy(() =>
  import('./privacy').then((module) => ({ default: module.Privacy }))
);
const LazyTerms = React.lazy(() =>
  import('./terms').then((module) => ({ default: module.Terms }))
);
const LazyContactUs = React.lazy(() =>
  import('./contact-us').then((module) => ({ default: module.ContactUs }))
);
const LazyMaxwellChat = React.lazy(() =>
  import('./maxwell').then((module) => ({ default: module.MaxwellChat }))
);
const LazyHowItWorks = React.lazy(() =>
  import('./how-it-works').then((module) => ({ default: module.HowItWorks }))
);
const LazyAccount = React.lazy(() =>
  import('./account').then((module) => ({ default: module.Account }))
);
const LazyUngatedPrematter = React.lazy(() =>
  import('./ungated-prematter').then((module) => ({
    default: module.PublicPrematter,
  }))
);
const LazySharedPrematter = React.lazy(() =>
  import('./shared-prematter').then((module) => ({
    default: module.SharedPrematter,
  }))
);
const LazyEditAccount = React.lazy(() =>
  import('./edit-account').then((module) => ({ default: module.EditAccount }))
);
const LazyLawyerConnection = React.lazy(() =>
  import('./lawyer-connection').then((module) => ({
    default: module.LawyerConnection,
  }))
);
const LazyLawyerConnectionClientIntakeForm = React.lazy(() =>
  import('./lawyer-connection/client-intake-form').then((module) => ({
    default: module.LawyerConnectionClientIntakeForm,
  }))
);
const LazyPrematterRouter = React.lazy(() =>
  import('./prematter').then((module) => ({ default: module.PrematterRouter }))
);
const LazyMaintenanceComponent = React.lazy(() =>
  import('./maintenance').then((module) => ({
    default: module.MaintenanceComponent,
  }))
);
const LazyLibrary = React.lazy(() =>
  import('./library').then((module) => ({ default: module.Library }))
);
const LazyNotFoundComponent = React.lazy(() =>
  import('./not-found').then((module) => ({
    default: module.NotFoundComponent,
  }))
);

const LazyHelp = React.lazy(() =>
  import('./help').then((module) => ({ default: module.Help }))
);

const LazyAnonymousPrematter = React.lazy(() =>
  import('./anonymous-prematter').then((module) => ({
    default: module.AnonymousPrematter,
  }))
);

interface PrivateRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
}

function PrivateRoute(args: PrivateRouteProps) {
  const { component } = args;
  const { isAuthenticated, isLoading, login } = useKindeAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const RenderComponent = component;
  const user = useAppSelector(selectors.user.getUser());
  const location = useLocation();
  const userError = useAppSelector(selectors.user.getFetchError());

  const [cookies, _setCookie, removeCookie] = useCookies([
    UNGATED_PREMATTER_COOKIE,
  ]);
  // we want to check cookies here for ungated-auth and if it exists, we want to redirect to the prematter page
  useEffect(() => {
    const ungatedCookie = cookies[UNGATED_PREMATTER_COOKIE];
    const prematterId = ungatedCookie?.prematterId;

    if (prematterId && ungatedCookie.type === 'save') {
      dispatch(actions.prematter.rejectVerify({ id: prematterId }));
      removeCookie(UNGATED_PREMATTER_COOKIE, {
        path: '/',
      });
    } else if (prematterId) {
      navigate(`/case/${prematterId}`);
    }
  }, [cookies, dispatch, navigate, removeCookie]);

  const prematters = useAppSelector(selectors.prematter.getAll());
  useEffect(() => {
    if (isAuthenticated && !user && !userError) {
      dispatch(actions.user.fetch());
    } else if (!isLoading && !isAuthenticated) {
      // Send user to login page
      login({
        // eslint-disable-next-line camelcase
        app_state: {
          redirectTo:
            location.state?.from?.pathname ||
            window.location.pathname ||
            '/account',
        },
      });
    }
  }, [
    dispatch,
    isAuthenticated,
    isLoading,
    location.state?.from?.pathname,
    login,
    navigate,
    user,
    userError,
  ]);

  useEffect(() => {
    if (isAuthenticated && prematters && prematters.length) {
      // if any of the prematters are in progress, refresh the list every 5 seconds
      const preparingReport = prematters.some(
        (prematter) => prematter?.state === LegalCaseState.PreparingReport
      );
      const processingQuestions = prematters.some(
        (prematter) =>
          prematter?.context?.questions?.filter((q) => q.processing).length > 0
      );

      if (preparingReport || processingQuestions) {
        const interval = setInterval(() => {
          // if any of the prematters are in progress, refresh the list every 5 seconds
          const preparingReport = prematters.some(
            (prematter) => prematter.state === LegalCaseState.PreparingReport
          );
          const processingQuestions = prematters.some(
            (prematter) =>
              prematter.context?.questions?.filter((q) => q.processing).length >
              0
          );
          if (!preparingReport && !processingQuestions) {
            clearInterval(interval);
            return;
          }
          dispatch(actions.prematter.fetch());
        }, 5000);
        return () => clearInterval(interval);
      }
    }
  }, [dispatch, isAuthenticated, prematters]);

  if (isLoading || !user) {
    return null;
  } else if (!isAuthenticated) {
    navigate('/');
    return <div>Redirecting...</div>;
  }
  return (
    <Suspense>
      <RenderComponent />
    </Suspense>
  );
}

const LogoutComponent: React.FC = () => {
  const { logout } = useKindeAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const logTheUserOut = async () => {
      await logout();
      // TOOD: Call dispatch to reset the redux store
      navigate('/login');
    };
    logTheUserOut();
  }, []);

  return null;
};

const RedirectComponent: React.FC<{ path: string }> = (props) => {
  const { path } = props;
  const navigate = useNavigate();
  useEffect(() => {
    navigate(path);
  }, [path]);
  return null;
};

const AuthCallbackComponent: React.FC = () => {
  const { isLoading, error, isAuthenticated } = useKindeAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && error) {
      console.error('Error logging in', error);
      navigate('/');
    } else if (!isLoading && !isAuthenticated) {
      console.error('User is not authenticated - but why?');
      navigate('/');
    } else if (!isLoading && isAuthenticated) {
      navigate('/account');
    }
  }, [isLoading, error, navigate, isAuthenticated]);

  return null;
};

const TrackPageView: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [location]);

  return null;
};

const AppWrapper = () => {
  const { isLoading, getToken, isAuthenticated, user } = useKindeAuth();

  const [languageLoaded, setLanguageLoaded] = useState(false);
  const location = useLocation();
  const [cookieResult, setCookieResult] = useState<CookieConsentResult>(
    CookieConsentResult.NONE
  );

  const t = useTranslation();

  useEffect(() => {}, []);

  useEffect(() => {
    const loadLanguage = async () => {
      await setLanguage(
        window?.localStorage?.i18nextLng || t.i18n.language || FALLBACK_LANGUAGE
      );
      setLanguageLoaded(true);
    };
    loadLanguage();
  }, [t.i18n.language]);

  // I really don't like this here as it's run on each render.
  // However, it's low cost and it's the only way to set the isAuthenticated state
  // before the first render, keeping it in sync with the auth state.
  security.setIsAuthenticated(isAuthenticated);

  useLayoutEffect(() => {
    const path = window.location.pathname;
    // Check for country and if does not follow with /library
    if (/^\/(au|uk|us|ca|nz)\b/.test(path) && !/\/library\b/.test(path)) {
      // Redirect to lawtap.com keeping path
      window.location.href = `https://lawtap.com${path}`;
    }
  }, []);

  useEffect(() => {
    security.setGetAccessToken(getToken);
  }, [getToken]);

  useEffect(() => {
    if (isAuthenticated && user) {
      // Set clarity custom tag for kinde user id
      clarity.setTag('Kinde User Id', user?.id);
    }
  }, [isAuthenticated, user]);

  const dispatch = useAppDispatch();
  const isFetchPending = useAppSelector(
    selectors.session.isFetchSessionPending()
  );
  const sessionId = useAppSelector(selectors.session.getSessionId());
  const isDeletingSession = useAppSelector(
    selectors.session.isDeleteSessionPending()
  );
  const fetchSessionError = useAppSelector(selectors.session.fetchError());

  const userDetail = useAppSelector(selectors.user.getUser());

  const handleCookeResult = (result: CookieConsentResult) => {
    if (result === CookieConsentResult.ACCEPT) {
      // In here is where we should initialize all the tracking services
      // aka we have been granted consent to track the user

      if (env.GTAG_CONTAINER_ID) {
        TagManager.initialize({
          gtmId: env.GTAG_CONTAINER_ID,
          preview: env.GTAG_PREVIEW,
          auth: env.GTAG_AUTH,
        });
      }

      ReactGA.initialize(
        env.GA_MEASUREMENT_ID,
        // {
        //   testMode:
        //     env.PUBLIC_URL.includes('localhost'),
        // }
      );
      clarity.consent();
      ReactPixel.grantConsent();
    }

    // We store the result in the state
    setCookieResult(result);
  };

  // On route change, if we have ReactPixel consent, log page view
  useEffect(() => {
    if (cookieResult === CookieConsentResult.ACCEPT) {
      ReactPixel.pageView();
    }
  }, [location, cookieResult]);

  useEffect(() => {
    if (
      !isLoading &&
      !isFetchPending &&
      !sessionId &&
      !fetchSessionError &&
      !isDeletingSession
    ) {
      dispatch(actions.session.fetch());
    }
  }, [
    dispatch,
    isFetchPending,
    sessionId,
    fetchSessionError,
    isLoading,
    isDeletingSession,
  ]);

  useEffect(() => {
    if (fetchSessionError) {
      // On error, retry fetch, and set interval to retry every 10 seconds
      // retry fetching the session in 10 seconds
      const interval = setInterval(() => {
        dispatch(actions.session.fetch());
      }, 10000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSessionError]);

  useEffect(() => {
    if (isAuthenticated && !userDetail) {
      dispatch(actions.user.fetch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userDetail]);

  useEffect(() => {
    document.body.classList.add(bodyStyles.body);
  }, []);

  useEffect(() => {
    const isIOSSafari = () => {
      const ua = navigator.userAgent;
      return (
        /iP(ad|hone|od)/.test(ua) && // Check for iOS devices
        /Safari/.test(ua) && // Check for Safari
        !/CriOS|FxiOS|OPiOS|EdgiOS/.test(ua) // Exclude Chrome, Firefox, Opera, Edge on iOS
      );
    };

    const handleTouchMove = (e: TouchEvent) => {
      // check if active element is a text input or textarea
      // if it is and it is on iOS Safari, blur the element
      // to prevent the safari white space bug
      if (
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement
      ) {
        document.activeElement.blur();
      }
    };

    // add touchmove listener to document to prevent white space bug on iOS Safari only
    if (isIOSSafari()) {
      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });

      return () => {
        document.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, []);

  // if (fetchSessionError) {
  //   // We could not get a session, that is a problem, but we cannot continue without it.
  //   return (
  //     <Router>
  //       <MaintenanceComponent />
  //     </Router>
  //   );
  // }

  const CookieWrapper = useMemo(() => {
    return;
  }, [handleCookeResult]);

  // if (isLoading || !t.i18n.language || !languageLoaded) {
  //   // This is to prevent kinde auth from redirecting to the homepage
  //   // so if it is loading, return null
  //   return null;
  // }
  return (
    <>
      <Collector userId={userDetail?.id} />
      <Helmet>
        <title>LawConnect</title>
      </Helmet>
      <ScrollToTop />
      <div id={AUTH_MODAL_PORTAL_ID} />
      <Routes>
        <Route
          path='/'
          element={
            <Suspense>
              <LazyHome />
            </Suspense>
          }
        />
        <Route
          path='/sailing'
          element={
            <Suspense>
              <LazySailingPage />
            </Suspense>
          }
        />
        <Route
          path='/about'
          element={
            <Suspense>
              <LazyAbout />
            </Suspense>
          }
        />
        <Route
          path='/privacy'
          element={
            <Suspense>
              <LazyPrivacy />
            </Suspense>
          }
        />
        <Route
          path='/terms'
          element={
            <Suspense>
              <LazyTerms />
            </Suspense>
          }
        />
        <Route
          path='/contact-us'
          element={
            <Suspense>
              <LazyContactUs />
            </Suspense>
          }
        />
        <Route path='/auth/*' element={<AuthCallbackComponent />} />
        <Route path='/logout' element={<LogoutComponent />} />
        <Route
          path='/how-it-works'
          element={
            <Suspense>
              <LazyHowItWorks />
            </Suspense>
          }
        />
        <Route
          path='/:languageCode/library/*'
          element={
            <Suspense>
              <LazyLibrary />
            </Suspense>
          }
        />
        <Route
          path='/help/*'
          element={
            <Suspense>
              <LazyHelp />
            </Suspense>
          }
        />
        {fetchSessionError && !isLoading ? (
          <Route path='*' element={<MaintenanceComponent />} />
        ) : isLoading || !t.i18n.language || !languageLoaded ? null : (
          <>
            {/* <Route
              path='/'
              element={
                <RouteWrapper
                  component={<Home />}
                  handleCookeResult={handleCookeResult}
                />
              }
            />
            <Route
              path='/sailing'
              element={
                <RouteWrapper
                  component={<SailingPage />}
                  handleCookeResult={handleCookeResult}
                />
              }
            /> */}

            <Route
              path='/chat/*'
              element={
                <Suspense>
                  <LazyMaxwellChat />
                </Suspense>
              }
            />
            <Route
              path='/account-force'
              element={
                <Suspense>
                  <LazyAccount />
                </Suspense>
              }
            />
            {/* we are ungating the cases route and having a read-only route for public view of prematter */}
            <Route
              path='/report'
              element={
                <Suspense>
                  <LazyUngatedPrematter />
                </Suspense>
              }
            />
            <Route
              path='/share/:prematterId'
              element={
                <Suspense>
                  <LazySharedPrematter />
                </Suspense>
              }
            />
            {/* <Route path='/shared/:id' element={<PublicPrematter />} /> */}
            {/* Authenticated routes */}
            <Route
              path='/account'
              element={<PrivateRoute component={LazyAccount} />}
            />
            <Route
              path='/account/edit'
              element={<PrivateRoute component={LazyEditAccount} />}
            />
            <Route
              path='/lawyer-connection/:id'
              element={<PrivateRoute component={LazyLawyerConnection} />}
            />
            <Route
              path='/lawyer-connection/:id/client-intake-form'
              element={
                <PrivateRoute
                  component={LazyLawyerConnectionClientIntakeForm}
                />
              }
            />
            <Route
              path='/case/:id/*'
              element={<PrivateRoute component={LazyPrematterRouter} />}
            />
            <Route path='/maintenance' element={<MaintenanceComponent />} />

            <Route path='*' element={<NotFoundComponent />} />
          </>
        )}
        <Route
          path='/anonymous-prematter/:prematterId'
          element={
            <Suspense>
              <LazyAnonymousPrematter />
            </Suspense>
          }
        />
      </Routes>
      <CookieConsent onResult={handleCookeResult} key={location.pathname} />
      <ToastWrapper />
    </>
  );
};

export default AppWrapper;
