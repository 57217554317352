import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import AppWrapper from './containers/app';
import store from './redux/store';
import 'swiper/css';
import loadEnv from './constants/env';
import globalStyle from './styles/global.module.less';
import ReactPixel from 'react-facebook-pixel';

import './i18n';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';

Sentry.init({
  environment: loadEnv.ENVIRONMENT,
  dsn: loadEnv.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/lawconnect\.com\/api/,
    /^https:\/\/staging.lawconnect\.com\/api/,
    /^https:\/\/development.lawconnect\.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

document.body.className = globalStyle.bodyRoot;

// todo: remove strict mdoe
ReactDOM.createRoot(document.getElementById('root')!).render(
  <KindeProvider
    clientId={loadEnv.KINDE_CLIENT_ID}
    domain={loadEnv.KINDE_DOMAIN}
    redirectUri={loadEnv.KINDE_REDIRECT_URI}
    logoutUri={loadEnv.KINDE_LOGOUT_REDIRECT_URI}
    audience={loadEnv.KINDE_AUDIENCE}
    scope='offline openid profile email'
    onRedirectCallback={(user, app_state) => {
      const newSignUp = window.localStorage.getItem('_lc_signup');
      if (newSignUp) {
        ReactPixel.track('SignedUp');
        window.localStorage.removeItem('_lc_signup');
      }
      if ((app_state as any)?.redirectTo === '/logout') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.location.href = (app_state as any)?.redirectTo;
      } else if (newSignUp) {
        window.location.href = `/case/${newSignUp}`;
      } else {
        window.location.href = '/account';
      }
    }}
  >
    <Provider store={store}>
      <Router>
        <AppWrapper />
      </Router>
    </Provider>
  </KindeProvider>
);
